/* eslint-disable */
import React from 'react'
import Helmet from '../../components/Helmet'

import { graphql } from 'gatsby'

import cuteCouple from '../../images/people/cute-couple-desktop.jpg'
import cuteCoupleMobile from '../../images/people/cute-couple-mobile.jpg'
import serviceAgent from '../../images/people/service-agent.jpg'

import { Phone } from 'emotion-icons/material'
import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import regence from '../../images/brand-logos/regence.svg'
import united from '../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import mutual from '../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'

import {
  Banner,
  Columns,
  Column,
  Link,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  useMapi,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/QuestionsHeader'
import footer from '../../components/Footer'
import GlobalBanner from '../../components/GlobalBanner'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-indexlp']

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title:
            'Medicare General and Open Enrollment Periods - What to Know | Healthcareplans.com',
          description:
            'Learn about two lesser-known Medicare enrollment periods that run from January 1 to March 31. Find out if you qualify for an enrollment period to review your Medicare coverage.',
          canonical: 'https://healthcareplans.com/',
          robots: 'follow,index',
        },
        path: '/',
        promoCode: '169379',
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Healthcareplans.com | 833-316-3576| Medicare Insurance',
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <VariableContent
          backgroundColor="dark"
          className="text-align-center-desktop"
        >
          <Typography variant="h1" style={{ color: '#ffffff' }}>
            Qualifying for Medicare Enrollment Outside of Annual Enrollment
            Period (AEP): General Enrollment and Open Enrollment Periods
          </Typography>
          <Typography
            variant="body"
            style={{ color: '#ffffff', marginBottom: '0' }}
          >
            Learn about the two Medicare enrollment periods that run from
            January through March.
          </Typography>
        </VariableContent>

        <VariableContent
          backgroundColor="lightgray"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">Key Takeaways</Typography>
            </>
          }
          style={{
            color: '#1b0b54',
          }}
        >
          <Stack>
            <Columns cardStyled>
              <Column style={{ padding: '12px 12px 0' }}>
                <StaticImage
                  src={'../../images/icons/calendar.svg'}
                  alt="calendar icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons centered"
                />
                <Typography
                  variant="body"
                  style={{ textAlign: 'center', marginBottom: '0' }}
                >
                  GEP and MAOEP are two additional lesser-known enrollment
                  periods running Jan 1 - Mar 31
                </Typography>
              </Column>
              <Column style={{ padding: '12px 12px 0' }}>
                <StaticImage
                  src="../../images/icons/65+.svg"
                  alt="calendar icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons centered"
                />
                <Typography
                  variant="body"
                  style={{ textAlign: 'center', marginBottom: '0' }}
                >
                  GEP (General Enrollment Period) allows enrollment for those
                  who missed their Initial Enrollment Period during their 65th
                  year
                </Typography>
              </Column>
              <Column style={{ padding: '12px 12px 0' }}>
                <StaticImage
                  src="../../images/icons/choices.svg"
                  alt="calendar icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons centered"
                />
                <Typography
                  variant="body"
                  style={{ textAlign: 'center', marginBottom: '0' }}
                >
                  The Medicare Advantage Open Enrollment Period (MAOEP) allows
                  Medicare Advantage plan holders to make changes during
                  January, February, and March each year
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          alignMainContent="left"
          mainContent={
            <>
              <img
                src={cuteCouple}
                className="show-on-desktop"
                alt="cute elderly couple"
                style={{ marginBottom: '24px' }}
              />
              <img
                src={cuteCoupleMobile}
                className="show-on-mobile"
                alt="cute elderly couple"
                style={{ marginBottom: '24px' }}
              />
              <Typography variant="h2">Medicare Enrollment Periods</Typography>
              <Typography variant="body">
                As you approach 65, it’s important to know about the various
                enrollment periods when you can sign up for Medicare coverage
                through the government and private insurers. The two primary
                enrollment periods for Medicare are the Initial Enrollment
                Period (IEP) and the Annual Enrollment Period (AEP).
              </Typography>
              <Typography variant="body">
                The Initial Enrollment Period (IEP) begins three months before
                you are first eligible for Medicare. For those qualifying for
                Medicare by age, this means IEP begins three months before you
                turn 65, includes the month in which you turn 65, and continues
                for three months after. When you sign up during IEP, coverage
                begins the month after signup.
              </Typography>
              <Typography variant="body">
                Medicare’s Annual Enrollment Period (AEP) occurs from October 15
                through December 7 every year. During AEP, people already
                enrolled in Medicare can do the following:
              </Typography>
              <List>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Join a Medicare Advantage Plan
                </ListItem>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Drop a Medicare Advantage Plan 
                </ListItem>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Switch to another Medicare Advantage Plan
                </ListItem>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Add or drop prescription drug coverage
                </ListItem>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Switch from Original Medicare (government-provided) to a
                  Medicare Advantage Plan
                </ListItem>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Switch from a Medicare Advantage Plan to Original Medicare
                  (government-provided)
                </ListItem>
              </List>
              <Typography variant="body">
                Beyond those two commonly used enrollment periods, there is an
                additional window from January 1 through March 31 where you can
                sign up for Medicare or make changes to a plan. This window
                covers the General Enrollment Period (for those who missed their
                Initial Enrollment Period in their age 65 year) and the Medicare
                Advantage Open Enrollment Period for those who already have a
                Medicare Advantage plan.
              </Typography>
              <br />
              <Typography variant="h4">
                What is the Medicare General Enrollment Period? Find out if
                you’re eligible.
              </Typography>
              <Typography variant="body">
                The Medicare General Enrollment Period, lasting from January 1
                through March 31 every year, is an enrollment period for those
                who missed their Initial Enrollment Period upon turning 65. If
                you have been eligible for Medicare for over a year and have not
                signed up yet, you may pay a late enrollment penalty of an extra
                10% for each year you could have signed up for Medicare Part B
                but did not.
              </Typography>
              <Typography variant="body">
                The General Enrollment Period is your first chance to apply for
                Medicare coverage if you missed your Initial Enrollment Period.
              </Typography>
              <br />
              <Typography variant="h4">
                Enrolling in Medicare During the General Enrollment Period
              </Typography>
              <Typography variant="body">
                You can use the Medicare General Enrollment Period to enroll at
                any time during January, February, and March. No matter when you
                enroll during those three months, your Medicare coverage will
                begin on July 1 of that year. 
              </Typography>
              <Typography variant="body">
                There are two ways to enroll during the General Enrollment
                Period:
              </Typography>
              <List>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Contact your local Social Security office over the phone or in
                  person
                </ListItem>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Enroll online at{' '}
                  <Link to="https://www.ssa.gov/medicare/sign-up">
                    https://www.ssa.gov/medicare/sign-up
                  </Link>
                </ListItem>
              </List>
              <Typography variant="body">
                If you have any questions on how to get started, give our
                helpful agents a call at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link> for
                assistance.
              </Typography>
              <br />
              <Typography variant="h4">
                What is the Medicare Advantage Open Enrollment Period? Find out
                if you’re eligible.
              </Typography>
              <Typography variant="body">
                The Medicare Advantage Open Enrollment Period (MAOEP) is
                concurrent with the General Enrollment Period, lasting from
                January 1 through March 31. This period allows Medicare
                Advantage Plan holders who enrolled during AEP to review their
                plan and make sure it fits their needs. Think of it as an extra
                chance to make sure the plan works with the plan holder’s chosen
                doctors, prescription coverage, and budget—if not, they can make
                one change to their coverage. It is important to note that this
                is not when you should enroll for Medicare Advantage for the
                first time; this is only to change existing coverage.
              </Typography>
              <br />
              <Typography variant="h4">
                What options do you have during MAOEP?
              </Typography>
              <Typography variant="body">
                The Medicare Advantage Open Enrollment Period is your
                one-time-per-year opportunity to make a change to your existing
                Medicare Advantage Plan. 
              </Typography>
              <Typography variant="body">
                Here are some things you can do during MAOEP:
              </Typography>
              <List>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Change from one Medicare Advantage Plan to another
                </ListItem>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Cancel Medicare Advantage and return to Original Medicare
                </ListItem>
                <ListItem className="leshen-list-item">
                  Add prescription drug coverage (Medicare Part D)
                </ListItem>
              </List>
              <Typography variant="body">During MAOEP, you cannot:</Typography>
              <ul className="leshen-list-item margin-bottom-0 bullets">
                <li>Enroll in a Medicare Advantage Plan for the first time</li>
                <li>Enroll in Original Medicare for the first time</li>
                <li>
                  Enroll in a Medicare Supplement (Medigap) Plan without
                  underwriting health question.
                </li>
              </ul>
              <br />
              <br />
              <Typography variant="h4">
                Understanding Medicare Enrollment Periods
              </Typography>
              <Typography variant="body">
                We understand the terminology around the various Medicare
                Enrollment Periods can be confusing. If you still have questions
                about the various Enrollment Periods or qualify for General
                Enrollment after delaying Medicare signup during your Initial
                Enrollment Period, please give us a call at 1-000-000-0000. Our
                expert US-based and US-licensed agents will be happy to help.
              </Typography>
            </>
          }
        />

        <VariableContent
          alignMainContent="left"
          backgroundColor="white"
          mainContent={
            <>
              <Typography variant="h2">Sources:</Typography>
              <Link to="https://www.cms.gov/priorities/key-initiatives/medicare-open-enrollment-partner-resources">
                https://www.cms.gov/priorities/key-initiatives/medicare-open-enrollment-partner-resources
              </Link>
              <Link to="https://www.cms.gov/training-education/find-provider-type/employers-unions/top-five-medicare-enrollment">
                https://www.cms.gov/training-education/find-provider-type/employers-unions/top-five-medicare-enrollment
              </Link>
              <Link to="https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan">
                https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan
              </Link>
              <Link to="https://www.medicare.gov/basics/get-started-with-medicare/sign-up/when-does-medicare-coverage-start">
                https://www.medicare.gov/basics/get-started-with-medicare/sign-up/when-does-medicare-coverage-start
              </Link>
              <Link to="https://www.medicarefaq.com/faqs/medicare-general-enrollment-period/">
                https://www.medicarefaq.com/faqs/medicare-general-enrollment-period/
              </Link>
              <Link to="https://www.ncoa.org/article/a-closer-look-at-the-medicare-general-enrollment-period">
                https://www.ncoa.org/article/a-closer-look-at-the-medicare-general-enrollment-period
              </Link>
            </>
          }
        />

        <SplitContent
          backgroundColor="light"
          alignImageToBottom={false}
          style={{
            color: '#1b0b54',
          }}
          image={
            <img
              src={serviceAgent}
              alt="a customer service agent sitting at desktop with computer and headset"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">Who We Are</Typography>
              <Typography variant="body">
                Healthcareplans.com is an independent, licensed insurance broker
                partnering with leading insurance brands. 
              </Typography>
              <Typography variant="body">
                If you qualify for one of the enrollment periods detailed above,
                click the button below to begin.
              </Typography>
              <LinkButton
                variant="feature"
                to="https://www.sunfirematrix.com/app/consumer/clearlink/#/"
                color="primary"
                className="margin-x-auto"
                requestId={requestId}
              >
                Get Started
              </LinkButton>
            </>
          }
        />

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor={null}
          customLink={null}
          className="brand-logos bg-lightgray"
        >
          <Typography variant="h3" style={{ textAlign: 'center' }}>
            Our Insurance Brand Partners
          </Typography>
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <GlobalBanner />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
